/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { 
    Flex, 
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableContainer,
    Badge, 
    Text,
    Heading,
    Stack,
    Progress,
    HStack,
    IconButton
} from '@chakra-ui/react';
import { UseApi } from "../../hooks/UseApi";
import { format, parseISO } from 'date-fns'
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

const Leads = () => {
    const [leads, setLeads] = useState([]); 
    const [ready, setReady] = useState(false); 
    const [page, setPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [totalLeads, setTotalLeads] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setLoading] = useState(true);

    const api = UseApi();

    const getLeadsFromApi = async (page) => {
        const data = await api.getLeads(page);
        if (data !== null) {
            setLeads(data.result);
            setTotalLeads(data.total);
            setTotalPages(Math.ceil(data.total / 20))
        }
        setReady(true);
        setLoading(false);
    }

    useEffect(() => {
       setTimeout(() => {
        getLeadsFromApi(page);
       }, 600) 
    },[])

    useEffect(() => {
        if (ready && page !== lastPage ) {
            setLoading(true);
            getLeadsFromApi(page);
        }
    }, [page])

    const nextPage = (event) => {
        if (page + 1 < totalPages) {
            setLastPage(page);
            setPage(page + 1)
        }
    };

    const previousPage = (event) => {
        if (page > 0) {
            setLastPage(page);
            setPage(page - 1);
        }
    };

    const renderDataDetails = () => {
        
        if (ready && leads.length > 1) {
            return (
                <HStack w="100%" justifyContent="flex-end" mb={4}>
                    <Text mr={4}>Total de registros: {totalLeads} | Página atual: {page + 1} de {totalPages}</Text>
                    <IconButton
                    colorScheme='blue'
                    aria-label='Search database'
                    icon={<FiArrowLeft />}
                    onClick={previousPage}
                    />
                    <IconButton
                    colorScheme='blue'
                    aria-label='Search database'
                    icon={<FiArrowRight />}
                    onClick={nextPage}
                    />
                </HStack>
            )
        } else {
            <></>
        }
        
    }

    const LeadsToRender = () => {
        if (leads.length > 0) {
            return (
                <>
                    <Tbody>
                    {
                        leads.map((value, index) => {
                            return (
                                <Tr key={value.id}>
                                    <Td>
                                        <Badge variant='solid' colorScheme='green'>
                                            healthy
                                        </Badge>
                                    </Td>
                                    <Td>{value.email}</Td>
                                    <Td>{value.campaign.name}</Td>
                                    <Td>{value.origin.name}</Td>
                                    <Td>{value.tag.name}</Td>
                                    <Td>{format(parseISO(value.createdAt), 'dd/MM/yyyy H:mm:ss')}</Td>
                                </Tr>
                            )
                        })
                    }
                    </Tbody>
                </>
            )
        } else {
            <Flex p={6}>
                SEM RESULTADOS
            </Flex>
        }
    }

    return (
        <>
            <Flex
            alignItems="flex-start" 
            justifyContent="flex-start" 
            direction="column"
            p={6}
            w="100%"
            >   
                <Stack spacing={4}>
                    <Heading as="h2" size="lg">Leads</Heading> 
                    <Text>Relatório de Leads gerados</Text>
                </Stack>
                <Flex mt={2} flexDir="column" w="100%" h={2}>
                {isLoading && <Progress size='xs' colorScheme="red" isIndeterminate />}
                </Flex>
                { ready && <Flex p={4} w="100%" justifyContent="flex-start">
                    <TableContainer w="100%" overflowY="visible">
                        {renderDataDetails()}
                        <Table variant='simple' size="sm">
                            <Thead>
                            <Tr>
                                <Th>Score</Th>
                                <Th>Email</Th>
                                <Th>Campanha</Th>
                                <Th>Origem</Th>
                                <Th>Tag</Th>
                                <Th>Data</Th>
                            </Tr>
                            </Thead>
                            {LeadsToRender()}
                            <Tfoot>
                            </Tfoot>
                        </Table>
                    </TableContainer> 
                </Flex>}
            </Flex>
        </>
    )
}

export default Leads;