import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import RequireAuth from '../Auth/RequireAuth'
import Dashboard from '../pages/Dashboard/dashboard'
import Leads from '../pages/Leads'
import PageNotFound from '../pages/PageNotFound'
import SignIn from '../pages/SingIn/SingIn'
import Users from '../pages/Users'
import Home from '../pages/Home'
import Insights from '../pages/Insights'
import CampaignPage from '../pages/Campaign'
import TagPage from '../pages/Tag'
import OriginPage from '../pages/Origin'
import SenderPage from '../pages/Sender'
import LogPage from '../pages/Log'

const AppRoutes: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<SignIn/>}/>
                <Route path='*' element={<Navigate to="/404" replace/>} />
                <Route path='404' element={<PageNotFound/>}/> 
                <Route path='dashboard' element={<RequireAuth><Dashboard/></RequireAuth>}> 
                    <Route path='' element={<Home/>}/>
                    <Route path='insights' element={<Insights/>}/>
                    <Route path='leads' element={<Leads/>}/>
                    <Route path='users' element={<Users/>}/>
                    <Route path='campaigns' element={<CampaignPage/>}/>
                    <Route path='tags' element={<TagPage/>}/>
                    <Route path='origins' element={<OriginPage/>}/>
                    <Route path='sender' element={<SenderPage/>}/>
                    <Route path='logs' element={<LogPage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes;

