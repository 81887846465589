import { useEffect, useState } from "react";
import { 
    Progress,
    Flex,
} from "@chakra-ui/react";

const Users = () => {
    const [users,setUsers] = useState([]);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setReady(true);
            setUsers([
                {
                    name: 'Murilo Anzini',
                    email: 'anzini.ad@gmail.com'
                }
            ])
        },900)
    }, [])

    const UserList = () => {
        if (users.length > 0) {
            return (
                <>Users</>
            )
        } else {
            return (
                <Flex p={12} justifyContent="center">
                    Sem usuários
                </Flex>
            )
        }
    }

    return (
        <>
            {ready ? <UserList/> : 
            <Progress colorScheme="red" size='xs' isIndeterminate hasStripe/>}
        </>
    )
    
}
    

export default Users;