import { 
    Avatar,
    Box,
    Flex, 
    HStack, 
    IconButton, 
    Menu, 
    MenuButton, 
    MenuItem, 
    MenuList, 
    useColorModeValue, 
    VStack,
    MenuDivider,
    Text,
} from '@chakra-ui/react'
import { FiBell, FiChevronDown } from 'react-icons/fi';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../Auth/AuthProvider';
import ColorModeSwitcher from '../../components/ColorModeSwitch';
import SideBar from '../../components/SideBar';


export default function Dashboard() {
    const { user } = useAuth();
    // return (
    //     <>
    //         <Flex
    //         height="20"
    //         alignItems="center"
    //         borderBottomWidth="1px"
    //         borderBottomColor={useColorModeValue('gray.100', 'gray.700')}
    //         justifyContent={{ base: 'space-between' }}
    //         >
    //             <HStack p={6} spacing={8}>
    //                 <Image src={Logo} alt="Welcome" width={8}/>
    //             </HStack>
    //             <Flex alignItems="center" justifyContent="space-between">
    //             <Stack direction='row' spacing={4}>
    //                 <Link to="/dashboard/">
    //                     <Button leftIcon={<FiHome />} variant='ghost'>
    //                         Home
    //                     </Button>
    //                 </Link>
    //                 <Link to="/dashboard/insights">
    //                     <Button leftIcon={<FiTrendingUp />} variant='ghost'>
    //                         Insights
    //                     </Button>
    //                 </Link>
    //                 <Link to="/dashboard/users">
    //                     <Button leftIcon={<FiUsers />} variant='ghost'>
    //                         Usuários
    //                     </Button>
    //                 </Link>
    //                 <Link to="/dashboard/leads">
    //                     <Button leftIcon={<FiTarget />} variant='ghost'>
    //                         Leads
    //                     </Button>
    //                 </Link>
    //             </Stack>
    //             </Flex>

                
                
    //         </Flex>
    //     </>
    // )

    // v2
    return (
        <>
            <Flex
                h="100vh"
                flexDir="row"
                overflow="hidden"
                w="100%"
            >
                {/* Column 1 */}
                <Flex w={260}>
                    <SideBar/>
                </Flex>
                <Flex flexDirection="column" w="100vw">
                    {/* topbar */}
                    <Flex h="55px" 
                    w="100%"
                    alignItems="center" 
                    justifyContent="flex-end" 
                    p={2}
                    boxShadow="0 5px 14px rgba(0,0,0,0.6)"
                    backgroundColor={useColorModeValue('#fff','#212529')}
                    >
                        <HStack p={6} spacing={{ base: '0', md: '0' }}>
                            <ColorModeSwitcher/>
                            <IconButton
                            size="lg"
                            variant="ghost"
                            aria-label="open menu"
                            icon={<FiBell/>}
                            />
                            <Flex alignItems={'center'}>
                            <Menu>
                                <MenuButton
                                py={2}
                                transition="all 0.3s"
                                _focus={{ boxShadow: 'none' }}>
                                <HStack>
                                    <Avatar
                                    size={'sm'}
                                    name={user.username}
                                    />
                                    <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2">
                                    <Text fontSize="sm">{user.username}</Text>
                                    <Text fontSize="xs" color="gray.600">
                                        Admin
                                    </Text>
                                    </VStack>
                                    <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                    </Box>
                                </HStack>
                                </MenuButton>
                                <MenuList
                                bg={useColorModeValue('white', 'gray.900')}
                                borderColor={useColorModeValue('gray.200', 'gray.700')}>
                                <MenuItem>Minha Conta</MenuItem>
                                <MenuDivider />
                                <MenuItem>Sair</MenuItem>
                                </MenuList>
                            </Menu>
                            </Flex>
                        </HStack>
                    </Flex>

                    {/* content */}
                    <Flex flexDirection="column" overflow="auto" backgroundColor={useColorModeValue('#F6F6F6','#1A1D21')} h="100vh" w="calc(100vw - 240px)" justifyContent="flex-start">
                        <Outlet />
                    </Flex>
                </Flex>
                

            </Flex>
        </>
        
        
    )
}