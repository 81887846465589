import axios from 'axios'

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

// type HeaderType = {
//     headers: string;
// } 

// const authHeader: HeaderType = () => {
//     return {
//         headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}`}
//     }
// }

export const UseApi = () => ({
    validateToken: async (token: string) => {
        const response = await api.post('/validateToken', { token });
        return response.data;
    },
    signIn: async (email: string, password: string) => {    
        let response = await api.post('/auth', { email, password });
        return response.data;
        
    },
    signOut: async () => {
        const response = await api.post('/logout');
        return response.data;
    },
    getLeads: async (page: number) => {
        let config = {
            headers: { 'Authorization': `token ${localStorage.getItem('authToken')}`},
            params: {
                page: page
            }
        }
        const response = await api.get('/lead/list', config)

        return response.data;
    },
    getCampaigns: async () => {
        const response = await api.get('/campaign/list',{
            headers: {
              'Authorization': `token ${localStorage.getItem('authToken')}`
            }
        })

        return response.data;
    },
    getOrigins: async () => {
        const response = await api.get('/origin/list',{
            headers: {
              'Authorization': `token ${localStorage.getItem('authToken')}`
            }
        })

        return response.data;
    },
    getTags: async () => {
        const response = await api.get('/tag/list',{
            headers: {
              'Authorization': `token ${localStorage.getItem('authToken')}`
            }
        })

        return response.data;
    },
    createCampaign: async (name: string, code: string) => {    
        let response = await api.post('/campaign/create', 
        { name, code }, 
        { headers: {
            'Authorization': `token ${localStorage.getItem('authToken')}`
        }});
        return response.data;
    },
    createOrigin: async (name: string, code: string) => {    
        let response = await api.post('/origin/create', 
        { name, code }, 
        { headers: {
            'Authorization': `token ${localStorage.getItem('authToken')}`
        }});
        return response.data;
    },
    createTag: async (name: string, code: string, position: string) => {    
        let response = await api.post('/tag/create', 
        { name, code, position }, 
        { headers: {
            'Authorization': `token ${localStorage.getItem('authToken')}`
        }});
        return response.data;
    },
    createSender: async (templateId: string, tag: string) => {    
        let response = await api.post('/sender', 
        { templateId, tag }, 
        { headers: {
            'Authorization': `token ${localStorage.getItem('authToken')}`
        }});
        return response.data;
    },
    getSenders: async () => {
        const response = await api.get('/sender/list',{
            headers: {
              'Authorization': `token ${localStorage.getItem('authToken')}`
            }
        })
        return response.data;
    },
})