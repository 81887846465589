import { motion } from "framer-motion";

const Insights = () => {
    return (
        <motion.div initial={{opacity:0, y:"50"}} animate={{opacity:1, y:0}} exit={{opacity:0}}>
            Insights
        </motion.div>
    )
}

export default Insights;