import { createContext, useContext, useEffect, useState } from "react";
import { UseApi } from "../hooks/UseApi";
import { User } from "../types/User";
// import { AuthContext } from "../Context/AuthContext";
import { Progress } from "@chakra-ui/react";

export type IAuthContext = {
    user: User | null;
    signIn: (email: string, password: string) => Promise<boolean>;
    signOut: () => void; 
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext)

const AuthProvider = ({ children }: { children: JSX.Element}) => {
    const [user, setUser] = useState<User | null>(null);
    const api = UseApi();

    // Ready é usado para printa ou não o componente na tela, antes de verificar sua autenticação
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const validateToken = async () => {
            const storageData = localStorage.getItem('authToken');
            if (storageData) {
                const data = await api.validateToken(storageData);
                if (data !== null && data.user) {
                    setUser(data.user);
                    setReady(true);
                } else {
                    // Alterar esse Ready 
                    setReady(true);
                }
            } else {
                setReady(true);
            }
        }
        setTimeout(() => {
            validateToken();
        }, 900)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    
    
    const signIn = async (email: string, password: string) => {
        try {
            const data = await api.signIn(email, password);
            if (data.user && data.token) {
                setUser(data.user);
                setToken(data.token);
                return true
            } 
        } catch {
            return false
        }

    }

    const signOut = async () => {
        await api.signOut();
        setUser(null);
    }

    const setToken = (token: string) => {
        localStorage.setItem('authToken', token);
    }
    

    return (
        <AuthContext.Provider value={{ user, signIn, signOut}}>
            {ready && children ? children :
                <>
                    {/* <Spinner color='red.500' /> */}
                    <Progress colorScheme="red" size='xs' isIndeterminate />
                </>
            }
            
        </AuthContext.Provider>
    );
}

function useAuth(): IAuthContext {
    const context = useContext(AuthContext);

    return context;
}

export { AuthProvider, useAuth};