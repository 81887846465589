import * as ReactDOM from 'react-dom/client';
import { ChakraProvider, extendTheme, type ThemeConfig } from '@chakra-ui/react';
import { AuthProvider } from './Auth/AuthProvider';
import AppRoutes from './Routes';

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

const theme = extendTheme({ config })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ChakraProvider theme={theme}>
      <AuthProvider>
        <AppRoutes/>
      </AuthProvider>
  </ChakraProvider>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorker.register()

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)