import {
    Text,
    Heading,
    Flex,
    Input,
    Button,
    useColorModeValue,
    Image,
    useToast,
    FormControl,
} from "@chakra-ui/react";
import ColorModeSwitcher from "../../components/ColorModeSwitch";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../Assets/Images/logo.png';
import { motion } from "framer-motion";
import { useAuth } from "../../Auth/AuthProvider";

export default function SignIn() {

    // const formik = useFormik({
    //     initialValues: {
    //         email: "",
    //         password: ""
    //     },
    //     onSubmit: (values, actions) => {
    //         alert(JSON.stringify(values, null, 2))
    //         actions.resetForm();
    //     } 
    // });
    const navigate = useNavigate();
    const toast = useToast();

    const { signIn } = useAuth();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    
    const formBackground = useColorModeValue("gray.50","gray.700");
    const variantInputColor = useColorModeValue("filled", "filled");

    const handleSignIn = async (event) => {
        event.preventDefault();
        if (email && password) {
            setLoading(true);
            const isLogged = await signIn( email, password );
            console.log(isLogged);
            if(!isLogged) {
                toast({
                    description: "Email ou senha inválidos!",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top-right',
                })
                setLoading(false);
            } else {
                navigate('dashboard')
            }
        } else {
            if (email === '') setInvalidEmail(true);
            if (password === '') setInvalidPassword(true);
        }
    }

    useEffect(() => {
        setInvalidEmail(false);
    }, [email])

    useEffect(() => {
        setInvalidPassword(false);
    }, [password])

    const handleEmailInputChange = (e: React.FormEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)
    const handlePasswordInputChange = (e: React.FormEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)

    return (
        <>
        <Flex alignItems='flex-end' justifyContent='flex-end'>
        <ColorModeSwitcher/>
        </Flex>
        <motion.div animate={{opacity:1, scale:1}} initial={{opacity:0, scale:0.6}} transition={{duration: 0.6}}>
            <Flex height='90vh' alignItems='center' justifyContent='center' direction='column'>
                <Flex p={6}>
                    <Image src={Logo} alt="Welcome" width={14}/>
                </Flex>
                <Flex direction='column' as="form" background={formBackground} p={12} rounded={6} onSubmit={handleSignIn}> 
                    <Heading mb={6}>Log In</Heading>
                    <FormControl isInvalid={invalidEmail}>
                        <Input 
                        id="email" 
                        placeholder='email@email.com' 
                        variant={variantInputColor} 
                        mb={3} 
                        type='email'
                        value={email}
                        onChange={handleEmailInputChange}
                        />
                    </FormControl>
                    <FormControl isInvalid={invalidPassword}>
                        <Input 
                        id="password" 
                        placeholder='*******' 
                        variant={variantInputColor} 
                        mb={6} 
                        type='password'
                        value={password}
                        onChange={handlePasswordInputChange}
                        />
                    </FormControl>
                    <Button 
                    isLoading={loading} 
                    mb={6} colorScheme='red' 
                    color="white"
                    backgroundColor="red.400"
                    type="submit" 
                    onClick={handleSignIn}>
                    Entrar
                    </Button>
                </Flex>
                <Flex direction='column' textAlign='center' p={6}>
                    <Text fontSize='sm'>Marketing platform</Text>
                    <Text fontSize='sm'>Learning Machine</Text>
                    <Text fontSize='xs'>Version - {process.env.REACT_APP_VERSION}</Text>
                    
                </Flex>
            </Flex>
        </motion.div>
        </>      
    )
}