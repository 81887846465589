import { 
    Flex, 
    useColorModeValue, 
    Image
} from "@chakra-ui/react"
import Logo from '../../Assets/Images/logo.png'
import SideBarMenu from "./SideBarMenu"

export default function SideBar() {
    
    return (
        <Flex
            pos="sticky"
            left="0"
            h="100vh"
            boxShadow="0 4px 14px 0 rgba(0,0,0,0.1)"
            w="240px"
            flexDir="column"
            backgroundColor={useColorModeValue('#fff','#212529')}
        >
            <Flex
                flexDir="column"
                justifyContent="flex-start"
                p={2}
            >   
                <Flex
                    p={2}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Image src={Logo} alt="Welcome" width={8}/>
                </Flex>
                <Flex
                    overflow="auto" 
                    flexDir="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    w="100%"
                    textAlign="left"
                    pl={2}
                    mt={4}
                >
                    <SideBarMenu/>
                </Flex>   
            </Flex>
        </Flex>
    )
}