import { Link } from 'react-router-dom';
import { 
    Accordion,
    AccordionItem, 
    AccordionButton, 
    AccordionIcon, 
    AccordionPanel,
    Icon, 
    Flex,
    useColorModeValue
} from "@chakra-ui/react";
import {
    FiHome,
    FiUsers,
    FiTarget,
    FiSettings,
    FiGitMerge,
  } from 'react-icons/fi';

const SideBarMenu = () => {
    return (
        <>
            <Flex w="100%" pl={4} mb={2}>
                <Link to="/dashboard/">
                <Flex flex='1' textAlign='left' justifyContent="flex-start" alignItems="center" color={useColorModeValue("#444444","#fcfcfc")}>
                <Icon
                    w={4}
                    h={4}
                    mr={2}
                    as={FiHome}
                />
                Home
                </Flex>
                </Link>
            </Flex>
            <Accordion defaultIndex={[0]} allowMultiple w="100%" color={useColorModeValue("#444444","#fcfcfc")}>
                <AccordionItem border={0}>
                    <h2>
                    <AccordionButton>
                        <Flex flex='1' textAlign='left' justifyContent="flex-start" alignItems="center">
                        <Icon
                            w={4}
                            h={4}
                            mr={2}
                            as={FiTarget}
                        />
                        Marketing
                        </Flex>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <Flex w="100%" mb={2}
                        >
                            <Flex mr={4}
                            >
                                -
                            </Flex>
                            <Link to="/dashboard/campaigns">
                                Campanhas
                            </Link>
                        </Flex>
                        <Flex w="100%" mb={2}
                        >
                            <Flex mr={4}
                            >
                                -
                            </Flex>
                            <Link to="/dashboard/origins">
                                Origem
                            </Link>
                        </Flex>
                        <Link to="/dashboard/tags">
                        <Flex w="100%"
                        >
                            <Flex mr={4}
                            >
                                -
                            </Flex>
                            
                                Tag
                        </Flex>
                        </Link>
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem border={0}>
                    <h2>
                    <AccordionButton>
                        <Flex flex='1' textAlign='left' justifyContent="flex-start" alignItems="center">
                        <Icon
                            w={4}
                            h={4}
                            mr={2}
                            as={FiUsers}
                        />
                        Leads
                        </Flex>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <Flex w="100%" mb={2}
                        >
                            <Flex mr={4}
                            >
                                -
                            </Flex>
                            <Link to="/dashboard/leads">
                             Lista
                            </Link>
                        </Flex>
                        <Link to="/dashboard/sender">
                        <Flex w="100%"
                        >
                            <Flex mr={4}
                            >
                                -
                            </Flex>
                            
                                Envios
                        </Flex>
                        </Link>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem border={0}>
                    <h2>
                    <AccordionButton>
                        <Flex flex='1' textAlign='left' justifyContent="flex-start" alignItems="center">
                        <Icon
                            w={4}
                            h={4}
                            mr={2}
                            as={FiSettings}
                        />
                        Configurações
                        </Flex>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                    EM BREVE!
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <Flex w="100%" pl={4} mb={2} mt={2}>
                <Link to="/dashboard/logs">
                <Flex flex='1' textAlign='left' justifyContent="flex-start" alignItems="center" color={useColorModeValue("#444444","#fcfcfc")}>
                <Icon
                    w={4}
                    h={4}
                    mr={2}
                    as={FiGitMerge}
                />
                Logs
                </Flex>
                </Link>
            </Flex>
        </>
    )
}

export default SideBarMenu;