import { 
    Flex, 
    Heading, 
    Select, 
    Stack, 
    Text,
    Input,
    VStack,
    Button,
    FormControl,
    Progress,
    TableContainer,
    Table,
    Thead,
    Tfoot,
    Th,
    Tr,
    Tbody,
    Td,
    useToast
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FiCheck } from "react-icons/fi";
import { UseApi } from "../../hooks/UseApi";
import { format, parseISO } from 'date-fns';

const SenderPage = () => {
    const [isLoading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(true);
    const [tags, setTags] = useState([]);
    const [templateId, setTemplateId] = useState('')
    const [tagSelected, setTagSelected] = useState('')

    const [senders, setSenders] = useState([])

    const [invalidTag, setInvalidTag] = useState(false)
    const [invalidTemplateId, setInvalidTemplateId] = useState(false)
    const toast = useToast();


    const api = UseApi();
    
    const getTags = async () => {
        try {
            const tags = await api.getTags();
            setTags(tags);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const getSenders = async () => {
        try {
            const result = await api.getSenders();
            setSenders(result);
        } catch (error) {
            console.log(error);
        } finally {
            setTableLoading(false);
        }
    }

    useEffect(() => {
        getTags();
        getSenders();
    }, [])

    useEffect(() => {
        setInvalidTemplateId(false);
    }, [templateId])

    useEffect(() => {
        setInvalidTag(false);
    },[tagSelected])

    const handleTemplateIdChange = (event) => setTemplateId(event.target.value)
    const handleTagChange = (event) => setTagSelected(event.target.value)

    const handleSubmit = async () => {
        setTableLoading(true);
        setLoading(true);
        if (tagSelected && templateId) {
            console.log(tagSelected, templateId);
            const result = await api.createSender(templateId, tagSelected)
            
            if (!result.error) {
                toast({
                    description: "Campanha enviada com sucesso!",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                })
            } else {
                toast({
                    description: "Erro ao enviar campanha",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                })
            }
        } else {
            if (tagSelected === '') setInvalidTag(true);
            if (templateId === '') setInvalidTemplateId(true);
        }
        getSenders();
        setLoading(false);
    }

    const renderSenders = () => {
        return (
            <Flex flexDir="column" mt={4}>
                { tableLoading ? <Progress size="xs" isIndeterminate colorScheme="red"/> : 
                <>
                    <TableContainer w="100%" overflowY="visible">
                        <Table variant='simple' size="sm">
                            <Thead>
                            <Tr>
                                <Th>Tag</Th>
                                <Th>TemplateId</Th>
                                <Th>Username</Th>
                                <Th>Data</Th>
                            </Tr>
                            </Thead>
                            <Tbody>
                            {
                                senders.map((value, index) => {
                                    return (
                                        <Tr key={value.id}>
                                            <Td>{value.tag}</Td>
                                            <Td>{value.templateId}</Td>
                                            <Td>{value.username}</Td>
                                            <Td>{format(parseISO(value.createdAt), 'dd/MM/yyyy H:mm:ss')}</Td>
                                        </Tr>
                                    )
                                })
                            }
                            </Tbody>
                            <Tfoot>
                            </Tfoot>
                        </Table>
                    </TableContainer> 
                </>
                }
            </Flex>
        )
    }

    return (
        <>
            <motion.div 
            style={{width:"100%", display:"flex"}} 
            initial={{opacity:0, y:"50"}} 
            animate={{opacity:1, y:0}} 
            exit={{opacity:0}}
            >
                <Flex
                w="100vw" 
                justifyContent="center" 
                p={6}
                flexDir="column"
                alignItems="center"
                >
                    <Flex minW={800} flexDir="column">
                        <Stack spacing={4}>
                            <Heading as="h2" size="lg">Envios</Heading> 
                            <Text>Disparar campanha de email</Text>
                        </Stack>
                        <Flex mt={12} justifyContent="space-between" alignItems="flex-end" flex={1}>
                            <VStack w="40%"> 
                                <Text mb='8px' w="100%">Tag para envio:</Text>
                                <FormControl isInvalid={invalidTag}>
                                <Select value={tagSelected} onChange={handleTagChange} placeholder='Selecionar tag' ml={0}>
                                    {
                                        tags.map((value, index) => {
                                            return (
                                                <option key={index} value={value.code}>{value.name}</option>
                                            )
                                        })
                                    }
                                </Select>
                                </FormControl>
                            </VStack>
                            <VStack w="40%">
                                <Text mb='8px' w="100%">Template ID (SendGrid):</Text>
                                <FormControl isInvalid={invalidTemplateId}>
                                <Input
                                    value={templateId}
                                    onChange={handleTemplateIdChange}
                                />
                                </FormControl>
                            </VStack>
                            <Button 
                            colorScheme='green'
                            rightIcon={<FiCheck />}
                            isLoading={isLoading}
                            onClick={handleSubmit}
                            >Enviar</Button>
                        </Flex>
                        {renderSenders()}
                    </Flex>
                    
                </Flex>
            </motion.div>
        </>
    )
}

export default SenderPage;