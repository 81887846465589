import { Flex, CircularProgress, CircularProgressLabel, Heading, Stack, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'

const Home = () => {
    return (
        <motion.div 
        initial={{opacity:0, y:"50"}} 
        animate={{opacity:1, y:0}} 
        exit={{opacity:0}}
        >
            <Flex justifyContent="center" flexDirection="column" alignItems="center" textAlign="center" p={24}>
                <Stack spacing={4} mb={12}>
                    <Heading as='h1' size='4xl' noOfLines={1}>
                        Em breve!
                    </Heading>
                    <Text as='h6' size='xs'>
                        "Todas as conquistas começam com o simples ato de acreditar que elas são possíveis"  ;)
                    </Text>
                </Stack>
                <CircularProgress value={5} color='red.500'>
                <CircularProgressLabel>5%</CircularProgressLabel>
                </CircularProgress>
            </Flex>
        </motion.div>
    )
}

export default Home;