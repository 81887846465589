import { Flex, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

const LogPage = () => {
    const [content, setContent] = useState("");

    useEffect(() => {
        fetch("/Logs.md")
        .then((res) => res.text())
        .then((text) => {
            setContent(text)
            console.log(text);
        });
    },[])

    return (
        <>
            <Flex flexDir="column" p={12}>
                <Heading mb={12}>
                    Log de atualizações
                </Heading>
                <ReactMarkdown children={content}/>
            </Flex>
        </>
    )
}

export default LogPage;