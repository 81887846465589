import {
    Flex,
    Spinner,
    Heading,
    Text,
    Stack, 
    Button,
    TableContainer,
    Table,
    Thead,
    Td,
    Th,
    Tr,
    Tbody,
    Tfoot,
    useToast,
    useDisclosure,
    Modal, 
    ModalContent,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
} from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi';
import { motion } from 'framer-motion';
import { UseApi } from '../../hooks/UseApi';
import { format, parseISO } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
 
const OriginPage = () => {
    const [ origins, setOrigins]  = useState([]);
    const [ ready, setReady ] = useState(false);

    const api = UseApi();

    useEffect(() => {
        getOrigins();
    }, [])

    const getOrigins = async () => {
        const data = await api.getOrigins();
        if (data !== null) {
            setOrigins(data);
        }
        setReady(true);
    }

    //form
    const [formLoading,setFormLoading] = useState(false);
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [invalidName, setInvalidName] = useState(false);
    const [invalidCode, setInvalidCode] = useState(false);
    const { isOpen, onOpen,  onClose } = useDisclosure()
    const toast = useToast();
    const initialRef = useRef(null);
    const finalRef = useRef(null);

    useEffect(() => {
        setInvalidName(false);
    }, [name])

    useEffect(() => {
        setInvalidCode(false);
    }, [code])

    const handleNameInputChange = (e: React.FormEvent<HTMLInputElement>) => setName(e.currentTarget.value)
    const handleCodeInputChange = (e: React.FormEvent<HTMLInputElement>) => setCode(e.currentTarget.value)

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (name && code) {
            setFormLoading(true);
            // const formSubmit = await api.createCampaing();
            const data = await api.createOrigin(name, code);
            if (data !== null) {
                setFormLoading(false);
                onClose();
                toast({
                    description: "Campanha criada com sucesso!",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                })
                getOrigins();
            } 
            else {
                setFormLoading(false);
                onClose();
                toast({
                    description: "Erro ao criar campanha #008",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                })
            }
        } else {
            if (name === '') setInvalidName(true);
            if (code === '') setInvalidCode(true);
        }
        // setFormLoading(false);
    }

    const renderModal = () => {
        return (
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={!formLoading}
                closeOnEsc={!formLoading}
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Criar campanha</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6} as="form" onSubmit={handleSubmit}>
                    <FormControl isInvalid={invalidName}>
                    <FormLabel>Nome</FormLabel>
                    <Input 
                    ref={initialRef} 
                    placeholder='Nome da campanha'
                    type="text"
                    value={name}
                    onChange={handleNameInputChange} 
                    />
                    </FormControl>

                    <FormControl mt={4} isInvalid={invalidCode}>
                    <FormLabel>Código</FormLabel>
                    <Input 
                    placeholder='exemplo-de-codigo' 
                    type="text"
                    value={code}
                    onChange={handleCodeInputChange}
                    />
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button 
                    colorScheme='blue'
                    mr={3} 
                    isLoading={formLoading} 
                    onClick={handleSubmit}>
                    Confirmar
                    </Button>
                    <Button onClick={onClose} disabled={formLoading}>Cancel</Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        )
    }

    return (
        <>
            {renderModal()}
            <motion.div initial={{opacity:0, y:"50"}} animate={{opacity:1, y:0}} exit={{opacity:0}} style={{width:"100%"}}>
                <Flex direction="column" 
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    w="100%" 
                    p={8}>
                        <Flex justifyContent="space-between" w="100%">
                            <Stack spacing={4}>
                                <Heading as="h2" size="lg">Fonte de Tráfego</Heading> 
                                <Text>Listagem das fontes de tráfego.</Text>
                            </Stack>
                            <Flex>
                            <Stack direction='row' spacing={4}>
                                <Button rightIcon={<FiPlus />} colorScheme='green' onClick={onOpen}>
                                    Criar Origem
                                </Button>
                            </Stack>
                            </Flex>
                        </Flex>
                        <Flex p={6} w="100%" justifyContent="flex-start">
                            { ready && origins ? 
                            <>
                                <TableContainer w="100%">
                                    <Table variant='simple' size="sm">
                                        <Thead>
                                        <Tr>
                                            <Th>Nome</Th>
                                            <Th>Código</Th>
                                            <Th>Criação</Th>
                                        </Tr>
                                        </Thead>
                                        <Tbody>
                                        {
                                            origins.map((value, index) => {
                                                return (
                                                    <Tr key={value.id}>
                                                        <Td>{value.code}</Td>
                                                        <Td>{value.name}</Td>
                                                        <Td>{format(parseISO(value.createdAt), 'dd/MM/yyyy h:m')}</Td>
                                                    </Tr>
                                                )
                                            })
                                        }
                                        </Tbody>
                                        <Tfoot>
                                        </Tfoot>
                                    </Table>
                                </TableContainer> 
                            </> 
                            : 
                            <>
                                <Spinner size="xl" color="red.500"/>
                            </>}
                        </Flex>
                    </Flex>
            </motion.div>
        </>
    )
}

export default OriginPage;